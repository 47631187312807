// import axios from 'axios';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import swapRouterContractABI from './swapRouterContractABI';

const web3 = new Web3(
  // new Web3.providers.HttpProvider(
  //   'https://mainnet.infura.io/v3/7c14d491733443adb20aae9f6510c5b7'
  // )
  window.ethereum
);

// const contractAddress = '0x1276c78df45cd17ba3128fe32e985e006b2f710d';
// const apiKey = 'GK8TFV7P3CEJE8FRGCFJP5XJQIT7JB25XZ';
const swapRouterAddress = '0x4A017b705cA756467Dcca7F2bc506aBa8515736D';
const wBNBAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const starAddress = '0x1276C78DF45CD17Ba3128fe32E985E006B2F710d';

const connectWallet = async () => {
  if (window.ethereum) {
    try {
      // 请求用户授权连接钱包
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      // accounts[0] 是用户选中的地址
      console.log('Connected address:', accounts[0]);
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
      alert('钱包连接失败');
    }
  } else {
    console.log('MetaMask is not installed!');
    alert('钱包没有安装');
  }
};

const App = () => {
  // const getContractABI = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://api.bscscan.com/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`
  //     );
  //     const { status, result } = res.data;
  //     const contractABI = JSON.parse(result);
  //     if (status !== '1' || contractABI === '') {
  //       throw new Error('获取合约ABI失败');
  //     }

  //     return contractABI;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const getContractInstance = async () => {
  //   try {
  //     const contractABI = await getContractABI();
  //     return new web3.eth.Contract(contractABI, contractAddress);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getSwapRouterContractInstance = async () => {
    try {
      return new web3.eth.Contract(swapRouterContractABI, swapRouterAddress);
    } catch (error) {
      console.log(error);
      alert('合约创建失败');
    }
  };
  const handleSwap = async (amount) => {
    try {
      await connectWallet();

      const swapRouterContractInstance = await getSwapRouterContractInstance();
      // params1: u
      const params1 = '1000';
      // params2: o
      const params2 = wBNBAddress;
      // params3: _
      const params3 = starAddress;
      // params4: h,
      const params4 = window.ethereum?.selectedAddress || void 0;
      const n = new BigNumber(amount).multipliedBy(1e18).toFixed();
      // params5: x
      const params5 = {
        from: params4,
        value: n,
      };

      console.log({
        params1,
        params2,
        params3,
        params4,
        params5,
      });

      await swapRouterContractInstance.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(
          params1,
          [params2, params3],
          params4,
          9999999999
        )
        .send(params5);

      alert('兑换成功!');
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div className='App'>
      <input
        style={{ width: 300, height: 30 }}
        type='number'
        id='amount'
        placeholder='请输入要用多少 BNB 兑换 STAR 代币'
      />
      <button
        id='buyButton'
        onClick={() => {
          const amount = document.getElementById('amount').value;
          try {
            if (!amount) {
              alert('购买 STAR 代币的 BNB 数量不能为空');
              return;
            }
            handleSwap(amount);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        买入
      </button>
    </div>
  );
};

export default App;
